import React from 'react';
import { Container } from 'theme-ui';
import PrimaryLayout from '../../components/layout/primary/primary';
//import AuthStateApp from '../../components/Authenticator/AuthStateApp'
import Payment from '../../components/checkout/payment'

const PaymentPage: React.FC<{}> = () => {

/*const [user, setUser] = React.useState({firstName: '', lastName: '', phone_number: '', email: ''});
React.useEffect(() => {
		async function getUser() {
			try {
			  let userInfo = await Amplify.Auth.currentUserInfo();
			  setUser({firstName: userInfo.attributes['custom:firstName'], lastName: userInfo.attributes['custom:LastName'], phone_number: userInfo.attributes.phone_number, email: userInfo.attributes.email})
	  
			} catch (error) {
				console.log('error getting details: ', error);
			}
		}
	  getUser();	
    }, []);*/
    
return (
	
	<PrimaryLayout>
		<Container py="40px">
			< Payment />
		</Container>
	</PrimaryLayout>
	
);
}

export default PaymentPage;
