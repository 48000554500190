/** @jsx jsx */
import React from 'react';
import { Label,Input, Box, Button, jsx, Spinner } from 'theme-ui';
import useRazorpay from "react-razorpay";
//import { Amplify, API } from 'aws-amplify';
import { LocalCartContext } from '../../provider/local-cart-provider';
//import styles from './payment.style';
import { navigate } from 'gatsby';

/*Amplify.configure({
  // OPTIONAL - if your API requires authentication 
  Auth: {
    identityPoolId: 'ap-south-1:dfea2fb1-c98b-4fae-91cb-56478cddae1f', // REQUIRED - Amazon Cognito Identity Pool ID
    region: 'ap-south-1', // REQUIRED - Amazon Cognito Region
    userPoolId: 'ap-south-1_sqESkxDgv', // OPTIONAL - Amazon Cognito User Pool ID
    userPoolWebClientId: '7g0n8d0f24kaiqmharucoivuv0', // OPTIONAL - Amazon Cognito Web Client ID (26-char alphanumeric string)
  },
  API: {
    endpoints: [
      {
        name: "chat2order-amazon",
        endpoint: "https://bxben0d8n3.execute-api.ap-south-1.amazonaws.com"
      }
    ]
  }
});*/

const Payment = () => {
  const [Razorpay, isLoaded] = useRazorpay();
  //const [loading, setLoading] = React.useState(false);
  
  //const [checkoutData, setCheckoutData] = React.useState({});
  //const [user, setUser] = React.useState('');
  //const { products, shipping, totalPrice } = React.useContext(LocalCartContext);
    
  const handlePayment = React.useCallback(() => {
    //const order = await createOrder(params);
    //setLoading(true)
    let checkoutData;
  	if(typeof window !== "undefined") {
		let params = (new URL(window.location)).searchParams;
		checkoutData = JSON.parse(decodeURIComponent(params.get("pd")));  
  	}
	const options = {
	  key: checkoutData.contact.includes('9739661365') ? "rzp_test_Sw7ZQDGDTJVlF4" : "rzp_live_KHMcCGKuFHFZ6G",
	  amount: checkoutData.amount,
	  currency: "INR",
	  name: checkoutData.shopName,
	  description: `transaction for ${checkoutData.rzpayOrder}`,
	  image: checkoutData.logo,
	  order_id: checkoutData.rzpayOrder,
	  handler: (res) => {
		console.log(res);
		window.open(`https://${checkoutData.shopName.toLowerCase()}.in/${checkoutData.shopName.toLowerCase()}/thankyou`, "_self")
	  },
	  modal: {
		backdropclose: true,
		handleback: false,
		ondismiss: () => { if ( typeof window !== "undefined"  && checkoutData.fromUrl ) { window.open(`${checkoutData.fromUrl}`, "_self") } }
	  },	  
	  prefill: {
		name: checkoutData.name,
		email: checkoutData.email,
		contact: checkoutData.contact,
	  },
	  notes: {
		masterOrder: checkoutData.masterOrder,
		shippinMethod: checkoutData.shippinMethod ?? '',
	  },
	  theme: {
		color: "#BB6F67",
	  }
	};

	const rzpay = new Razorpay(options);
	rzpay.open();		
	//setCheckoutData(webcheckoutData);
	    

  }, [Razorpay]);

  /*React.useEffect(() => {
	(async () => {
		let user = await Amplify.Auth.currentUserInfo();	
		setUser(user.username)
	})() 	
  	console.log(user)
	function getData() {
		if(user) {
		  const apiName = 'chat2order-amazon';
		  const path = '/default/chat2order-shops/webcheckout';
		  let body = {};
		  body.user = user
		  body.shipping = shipping
		  body.totalPrice = totalPrice
		  body.lineItems = [];
		  products.map((product) => {
			let item = {};
			item.variantId = product.variantId;
			item.quantity = product.quantity;
			item.price = product.price;
			body.lineItems.push(item)
		  })
		  const myInit = {
			body: body,	  
			headers: {} // OPTIONAL
		  };

		  return API.post(apiName, path, myInit);	
		}
	}
	(async function() {
		const webcheckoutData = await getData();
		setCheckoutData(webcheckoutData);
	})();		
  },[products])*/
  React.useEffect(() => {
    if (isLoaded) {
      handlePayment();
    }
  }, [isLoaded, handlePayment])
  
	return (
		<>
			<Button onClick={handlePayment} >Pay</Button>		
		</>
	)
}


export default Payment;